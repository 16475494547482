import React, { useContext } from "react";
import { Link } from "gatsby";
import { GMDContext } from "../../context/GMDContext";
import { currentYear } from "../../../utils/common";
import { ModeToggles } from "../mode/ModeToggles";
// import { TextImageMode } from "../mode/TextImageMode";
import * as styles from "./Footer.module.scss";

const Footer = ({ className, location }) => {
  const { studentYear } = useContext(GMDContext);
  const link = location.pathname.split("/")[1];

  const studentPage = link === "students" ? true : false;

  const linkTo =
    link === "students"
      ? studentYear === currentYear
        ? "show"
        : "archive"
      : link;

  return (
    <footer className={styles.footer}>
      <section>
        <a href="https://gmdlcc.com">
          Go to www.gmdlcc.com to see the Graphic and Media Design degree show,
          archive and stories.
        </a>
      </section>
    </footer>
  );
};

export { Footer };
